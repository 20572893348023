import "../prism.less";
import "apollo-algolia-autocomplete/styles.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";
import Autocomplete from "apollo-algolia-autocomplete";
import DocsetSwitcher from "./docset-switcher";
import Header from "./header";
import HeaderButton from "./header-button";
import PropTypes from "prop-types";
import React, { createContext, useMemo, useRef, useState } from "react";
import styled from "@emotion/styled";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { Button } from "@apollo/space-kit/Button";
import {
  Layout,
  MenuButton,
  Sidebar,
  SidebarNav,
  breakpoints,
  colors,
  useResponsiveSidebar,
} from "gatsby-theme-apollo-core";
import FlexWrapper from "./flex-wrapper";
import colorsDark from "../../gatsby-theme-apollo-core/utils/colorsDark";
import { Helmet } from "react-helmet";
import { Link, graphql, navigate, useStaticQuery } from "gatsby";
import { MobileLogo } from "./mobile-logo";
import { SelectedLanguageContext } from "./multi-code-block";
import { getVersionBasePath, trackCustomEvent } from "../utils";
import { groupBy } from "lodash";
import { login, isAuthenticated } from "../../components/utils/auth";
import Logo from "./logo";
import logoMoon from "../../assets/moon.png";
import logoSun from "../../assets/sun.png";
import { Auth0Login } from "../../components/auth0login";
import { Select } from "./select";
import { IconLayoutModule } from "@apollo/space-kit/icons/IconLayoutModule";
import { size } from "polished";

// Search Functionality
import Search from "../../components/search";
const searchIndices = [{ name: `Pages`, title: `Pages` }];

export const NavItemsContext = createContext();

export default function PageLayout(props) {
  // Dark Theme
  colorsDark = {
    primary: "#FFFFFF",
    primaryLight: "#91B9C4",
    secondary: "#FFFFFF",
    tertiary: "#949494",
    tertiaryLight: "#AFAFAF",
    divider: "#DEE2E7",
    background: "#91B9C4",
    background2: "#001933",
    backgroundNotice: "#E8C161",
    backgroundColor: "#2C282B",
    sideBgColor: "#545257",
    text1: "#FFFFFF",
    text2: "#A8A8A8",
    text3: "#808080",
    text4: "#A8A8A8",
    textNotice: "#AD7900",
    warning: "#AD7900",
    shadow: "#000000",
    highlight: "#E8C161",
    highlight2: "#FFAE00",
    highlight3: "#FFDC96",
    codeComment: "#AD7900",
    hoverOpacity: 0.8,
  };

  const [activeTheme, setActiveTheme] = useState(colors);
  function isLightModeActive() {
    return activeTheme === colors;
  }
  if (typeof window !== "undefined") {
    if (JSON.parse(window.localStorage.getItem("isDark") === null)) {
      // User preference is saved
      localStorage.setItem("isDark", false);
    } else if (
      JSON.parse(window.localStorage.getItem("isDark")) === true &&
      isLightModeActive()
    ) {
      toggleTheme();
    }
  }
  function toggleTheme() {
    if (isLightModeActive()) {
      setActiveTheme(colorsDark);
      window.localStorage.setItem("isDark", true);
      document.body.style.color = "#FFFFFF";
      //document.getElementById("StyledLink").style.color = "#FFFFFF";
    } else {
      setActiveTheme(colors);
      window.localStorage.setItem("isDark", false);
      document.body.style.color = "#000000";
      //document.getElementById("h1").style.color = activeTheme.text2;
    }
  }

  // Main Styling
  const Main = styled.main({
    flexGrow: 1,
    marginLeft: "5%",
    marginRight: "5%",
  });

  const ButtonWrapper = styled.div({
    flexGrow: 1,
  });

  const Side = styled.div({
    backgroundColor: activeTheme.sideBgColor,
  });

  const MobileNav = styled.div({
    display: "none",
    [breakpoints.md]: {
      display: "flex",
      alignItems: "center",
      marginRight: 32,
      color: activeTheme.text1,
    },
  });

  const HeaderInner = styled.span({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 32,
  });

  const Eyebrow = styled.div({
    flexShrink: 0,
    padding: "8px 56px",
    backgroundColor: activeTheme.backgroundNotice,
    color: activeTheme.textNotice,
    fontSize: 16,
    position: "sticky",
    top: 0,
    a: {
      color: "inherit",
      fontWeight: 600,
    },
    [breakpoints.md]: {
      padding: "8px 24px",
    },
  });

  const SearchContainer = styled.div({
    flexGrow: 1,
    marginRight: 40,
    [breakpoints.md]: {
      marginRight: 0,
    },
  });

  function getVersionLabel(version) {
    return `v${version}`;
  }

  const GA_EVENT_CATEGORY_SIDEBAR = "Sidebar";

  function handleToggleAll(expanded) {
    trackCustomEvent({
      category: GA_EVENT_CATEGORY_SIDEBAR,
      action: "Toggle all",
      label: expanded ? "expand" : "collapse",
    });
  }

  function handleToggleCategory(label, expanded) {
    trackCustomEvent({
      category: GA_EVENT_CATEGORY_SIDEBAR,
      action: "Toggle category",
      label,
      value: Number(expanded),
    });
  }

  const StyledIcon = styled(IconLayoutModule)(size(16), {
    marginLeft: "auto",
  });

  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteName
          }
        }
      }
    `
  );

  const {
    sidebarRef,
    openSidebar,
    sidebarOpen,
    handleWrapperClick,
    handleSidebarNavLinkClick,
  } = useResponsiveSidebar();

  const buttonRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const selectedLanguageState = useLocalStorage("docs-lang");

  function openMenu() {
    setMenuOpen(true);
  }

  function closeMenu() {
    setMenuOpen(false);
  }

  const { pathname } = props.location;
  const { siteName, title } = data.site.siteMetadata;
  const {
    subtitle,
    sidebarContents,
    versions,
    versionDifference,
    versionBasePath,
    defaultVersion,
  } = props.pageContext;
  const {
    twitterHandle,
    youtubeUrl,
    navConfig = {},
    footerNavConfig,
    logoLink,
    menuTitle,
    algoliaAppId,
    algoliaSearchKey,
    algoliaFilters = [],
  } = props.pluginOptions;

  const { navItems, navCategories } = useMemo(() => {
    const navItems = Object.entries(navConfig).map(([title, navItem]) => ({
      ...navItem,
      title,
    }));
    return {
      navItems,
      navCategories: Object.entries(groupBy(navItems, "category")),
    };
  }, [navConfig]);

  const hasNavItems = navItems.length > 0;

  // Handling Authentication
  if (!isAuthenticated()) {
    login();
    if (typeof window !== "undefined") {
      localStorage.setItem("isLoggedIn", true);
    }
    return null;
  }

  // Top Bar Styling
  const TopWrapper = styled.div({
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    backgroundColor: "#247489",
    justifyContent: "center",
    height: 70,
    minWidth: 600,
    paddingRight: 20,
    borderBottom: "1px solid white",
  });

  const SideWrapper = styled.div({
    display: "flex",
  });

  const StyledButton = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    margin: 0;

    img,
    button {
      background-color: #fff;
      color: #247489;
      padding: 5px;
      border-radius: 20px;
      border: none;
      margin-right: 30px;
      margin-left: 30px;

      font-size: 15px;
      font-weight: bold;
    }

    img:hover,
    button:hover {
      background-color: #91b9c4;
      color: #fff;
      cursor: pointer;
    }
  `;

  return (
    <Layout>
      <TopWrapper>
        <Logo />
        <Search indices={searchIndices} />
        <SideWrapper>
          <StyledButton>
            {isLightModeActive() ? (
              <img
                src={logoMoon}
                alt={"logo"}
                width="40px"
                height="40px"
                onClick={toggleTheme}
              />
            ) : (
              <img
                src={logoSun}
                alt={"logo"}
                width="40px"
                height="40px"
                onClick={toggleTheme}
              />
            )}
          </StyledButton>
          {/*<Auth0Login />*/}
        </SideWrapper>
      </TopWrapper>
      <Helmet
        titleTemplate={["%s", subtitle, title].filter(Boolean).join(" - ")}
      >
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/docsearch.js@2/dist/cdn/docsearch.min.css"
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <FlexWrapper onClick={handleWrapperClick} theme={activeTheme}>
        <Side>
          <Sidebar
            responsive
            className="sidebar"
            open={sidebarOpen}
            ref={sidebarRef}
            title={siteName}
            logoLink={logoLink}
            theme={activeTheme}
          >
            {sidebarContents && (
              <SidebarNav
                contents={sidebarContents}
                pathname={pathname}
                onToggleAll={handleToggleAll}
                onToggleCategory={handleToggleCategory}
                onLinkClick={handleSidebarNavLinkClick}
                theme={activeTheme}
              />
            )}
          </Sidebar>
        </Side>
        <Main>
          <Header
            beforeContent={
              versionDifference !== 0 && (
                <Eyebrow>
                  You&apos;re viewing documentation for a{" "}
                  {versionDifference > 0
                    ? "version of this software that is in development"
                    : "previous version of this software"}
                  . <Link to="/">Switch to the latest stable version</Link>
                </Eyebrow>
              )
            }
          >
            <MobileNav>
              <MenuButton onClick={openSidebar} />
              <MobileLogo width={32} fill="currentColor" />
            </MobileNav>
            <HeaderButton />
          </Header>
          <SelectedLanguageContext.Provider value={selectedLanguageState}>
            <NavItemsContext.Provider value={navItems}>
              {props.children}
            </NavItemsContext.Provider>
          </SelectedLanguageContext.Provider>
        </Main>
      </FlexWrapper>
      {hasNavItems && (
        <DocsetSwitcher
          siteName={menuTitle || siteName}
          twitterUrl={twitterHandle && `https://twitter.com/${twitterHandle}`}
          youtubeUrl={youtubeUrl}
          navItems={navItems}
          navCategories={navCategories}
          footerNavConfig={footerNavConfig}
          open={menuOpen}
          buttonRef={buttonRef}
          onClose={closeMenu}
        />
      )}
    </Layout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  pluginOptions: PropTypes.object.isRequired,
};
